import "./../App.css";
import React, { useState } from "react";


export default function PageNotFound() {

    return(

        <div className="w-full h-[100vh] bg-[#051525] flex flex-col items-center justify-center text-white"
        ><h1 className="ont-opensans font-bold md:text-[4rem] ">404</h1>
        <p className="font-opensans font-medium md:text-lg text-sm"> Page Not Found</p>
        <a className="font-opensans font-medium md:text-sm text-[0.6rem] underline text-white hover:text-gray-400" href="/">Back to Home</a>
        </div>

    );
}