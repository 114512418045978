import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import Productscard from '../Components/Productscard';
import { software } from '../Data/Products';
import "./../App.css";

export default function Software() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="w-full bg-[#051525]">
      <Header id={3} />
      <Banner
        mainBanner={
          <div className="relative h-[95vh] flex items-center justify-center">
            <img
              className="w-full h-[95vh] object-cover "
              src={require("../../src/Assets/images/Software.jpeg")}
            ></img>
            <div className="flex flex-col items-start justify-center transformleft sm:left-14 left-4  gap-4">
              <div
                className=" font-opensans font-bold text-6xl text-left hidden md:block"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                Software
              </div>
              <div
                className=" font-opensans font-bold text-3xl text-left block md:hidden"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
                Software
              </div>
              <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white sm:w-[25.68rem] w-[20rem] text-left">
              "Quantum Software: Unlocking Parallel Realities, Empowering Infinite Solutions." In the digital frontier of qubits and algorithms, quantum software orchestrates the symphony of quantum computers. From groundbreaking algorithms to seamless coding languages, it opens doors to unparalleled computational potential. Quantum software: where virtual meets the vibrancy of the quantum world, redefining what's achievable. 
              </p>
            </div>
          </div>
        }
      />
       <div className="flex flex-col items-center justify-center md:px-20 md:py-10 px-3 py-7">
        <p className="font-opensans font-extrabold 2xl:text-3xl md:text-2xl text-[#E7EAEE]">
          Software Products
        </p>
        <div className="flex flex-wrap max-w-full gap-5 mx-auto items-center justify-center md:px-20 md:py-10 px-3 py-7 cursor-pointer">
          {software.map((item) => (
            <div key={item.id} className="flex-item ">
              <Productscard
                
                head={item.head}
                
                content={item.content}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer showpage={false}/>
      </div>
      
  );
}
