import React, { useState, useRef } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import "./../App.css";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbMail } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";
import emailjs from '@emailjs/browser';

export default function Contactus() {

 
    const [subject, setSubject] = useState(null);
    const [body, setBody] = useState(null);
    
    const form = useRef();
    
    const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs
    .sendForm('service_cui6i0a', 'template_p94hjas', form.current, {
    publicKey: 'rcWUEyKg3TYfCLt2-',
    })
    .then(
    () => {
    console.log('SUCCESS!');
    },
    (error) => {
    console.log('FAILED...', error.text);
    },
    );};
  
  return (
    <div className="w-full bg-[#051525]">
      <Header id={6} />
      <Banner
        mainBanner={
          <div className="relative h-[95vh] flex items-center justify-center">
            <img
              className="w-full h-[95vh] object-cover "
              src={require("../../src/Assets/images/Contactus.jpeg")}
            ></img>
            <div className="flex flex-col items-start justify-center transformleft sm:left-14 left-4 gap-4">
            <div
              className=" font-opensans font-bold text-6xl text-left hidden md:block "
              style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
            >
              Contact Us
            </div>
            <div
                className=" font-opensans font-bold text-3xl text-left block md:hidden"
                style={{ WebkitTextStroke: "2px white ", color: "transparent" }}
              >
               Contact Us
              </div>
              <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white sm:w-[25.68rem] w-[20rem]  text-left">
                Get in touch today! Your questions matter. We're ready to help.
                Reach out for personalized support and seamless communication.
              </p>
            </div>
          </div>
        }
      />
      <p className="mt-12 w-full font-opensans font-semibold md:text-[1rem] text-[0.6rem] text-white text-center mb-1">
                    For any queries, Send us an email to : <span className="text-[#04E3FF]">info@qulabs.ai</span>
                    </p>
      <div className="2xl:w-[70%] md:w-[90%] mx-auto flex md:flex-row flex-col md:gap-0 gap-8 items-center justify-center md:justify-between px-10 py-10 ">
        <div className=" px-10 py-6 bg-white/10  contact-shadow ">
          <h2 className="md:text-[2.1rem] text-[1.5rem] font-opensans font-extrabold mb-6 text-[#D9FBFF]/50">
            Send Us a Message
          </h2>
          <form ref={form} onSubmit={sendEmail} >
            <div className="md:mb-4 mb-1">
              <label
                htmlFor="name"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="user_name"
               
                className="mt-1 md:p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
                required
              />
            </div>

            <div className="md:mb-4 mb-1">
              <label
                htmlFor="phoneNumber"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="user_phoneNumber"
                
                
                className="mt-1 md:p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
                required
              />
            </div>

            <div className="md:mb-4 mb-1">
              <label
                htmlFor="email"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans" 
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="user_email"
               
                onChange={(e) => setSubject(e.target.value)}
                className="mt-1 md:p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
                required
              />
            </div>

            <div className="md:mb-4 mb-1">
              <label
                htmlFor="message"
                className="block md:text-sm text-[0.6rem] font-extrabold text-white font-opensans"
              >
                Message
              </label>
              <textarea onChange={(e) => setBody(e.target.value)} 
                id="message"
                name="user_message"
               
               
                rows="4"
                className="mt-1 md:p-2 w-full border-b text-white border-[#D9FBFF]/50 rounded-md bg-white/10 cursor-pointer"
                required
              ></textarea>
            </div>

            

            <button
              type="submit"
              className="bg-[#D9FBFF]/60 text-white text-[0.6rem] md:text-sm md:px-4 md:py-2 px-2 py-1 rounded-md hover:bg-[#D9FBFF]/30"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="md:w-auto w-full rounded-[0.3rem] h-auto flex flex-col items-center justify-center bg-[#0C2E52] gap-3 md:gap-6 md:py-12 md:px-8 py-6 ">
          <a
            href="https://www.google.com/maps/place/Qulabs+Software+(India)+Pvt.+Ltd./@17.426882,78.4427399,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb91d51fc46755:0xe39c60f435e2b5dc!8m2!3d17.4268769!4d78.4453148!16s%2Fg%2F11jzpq2lsc?entry=ttu"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center gap-5 md:w-[24.3rem] w-[85%]  bg-white/25 rounded-2xl px-[1.875rem] py-[0.7rem] contactus-shadow  cursor-pointer hover:bg-white/10"
          >
            <HiOutlineLocationMarker size={24} color="#1B99D4" className="md:block hidden "/>
            <HiOutlineLocationMarker size={18} color="#1B99D4" className="block md:hidden "/>
            <div className="flex flex-col items-start justify-center gap-1">
              <div className="font-opensans font-extrabold text-white text-[0.6rem] md:text-[0.9rem]">
                Location
              </div>
              <div className="flex flex-wrap md:w-[18rem] w-[10rem] text-[#D9FBFF] opacity-50 font-opensans md:text-sm text-[0.45rem] font-normal ">
                Qulabs Software (India) Pvt Ltd 8-2-351/N/1, 4th floor,
                Banjara Hills Road No.3, Hyderabad-500034,Telangana
              </div>
            </div>
          </a>
          <a
            href={`mailto:${"info@qulabs.ai"}`}
            className="flex items-center justify-center gap-5 md:w-[24.3rem] w-[85%] bg-white/25 rounded-2xl px-[1.875rem] py-[0.7rem] contactus-shadow cursor-pointer hover:bg-white/10"
          >
            <TbMail size={24} color="#1B99D4" className="md:block hidden" />
            <TbMail size={18} color="#1B99D4" className="block md:hidden" />
            <div>
              <div className="font-opensans font-extrabold text-white text-[0.6rem] md:text-[0.9rem]">
                Email Us
              </div>
              <div className="flex flex-wrap  md:w-[18rem] w-[10rem] text-[#D9FBFF] opacity-50 font-opensans md:text-sm text-[0.45rem] font-normal ">
              info@qulabs.ai
              </div>
            </div>
          </a>
          <a
            href="+91 6281684342"
            className="flex items-center justify-center gap-5 md:w-[24.3rem] w-[85%]  bg-white/25 rounded-2xl px-[1.875rem] py-[0.7rem] contactus-shadow cursor-pointer hover:bg-white/10"
          >
            <IoCallOutline size={24} color="#1B99D4" className="md:block hidden"/>
            <IoCallOutline size={18} color="#1B99D4" className="block md:hidden"/>
            <div>
              <div className="font-opensans font-extrabold text-white text-[0.6rem] md:text-[0.9rem]">
                Call Us
              </div>
              <div className="flex flex-wrap md:w-[18rem] w-[10rem] text-[#D9FBFF] opacity-50 font-opensans md:text-sm text-[0.45rem] font-normal ">
                +91 6281684342
              </div>
            </div>
          </a>
        </div>
        
      </div>
      
      <Footer showpage={false} />
    </div>
  );
}



