import React, { useState } from 'react';
import { LuArrowRight } from 'react-icons/lu';

export default function Logocard(props) {
  const [isHovered, setHovered] = useState(false);
  const [Read ,setRead]=useState(false);

  const handleReadMoreClick = () => {
    setRead(true);
    // Send data to Logoscontainer
    props.onReadMoreClick({ id: props.id }); // Assuming you have a 'name' prop in Logocard
  };
 

  return (
    <div className="md:w-[23rem] md:h-[27rem] w-[15rem] h-[17rem] rounded-lg bg-[#09233E] hover:bg-[#D9FBFF]/40 md:rounded-2xl flex flex-col items-center justify-center md:gap-7 gap-4 px-[1rem] py-[1.5rem] shadow-custom">
      <img className="md:w-[16rem] md:h-[9.4rem] w-[9.5rem] h-[5.5rem] rounded-lg object-cover md:rounded-2xl" src={props.logo} />

      <div className="md:w-[22rem] w-[13rem] p-1 md:p-4">
        <p className="text-white font-opensans font-semibold md:text-sm text-[0.55rem]">{props.para}</p>
      </div>

      <div className="w-full flex items-center justify-end">
        <div
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={`flex items-center justify-center gap-1 hover:bg-[#0E3054] rounded-2xl px-2 py-1 cursor-pointer ${
            isHovered ? 'bg-[#0E3054]' : ''
          }`}
        >
             <div
                onMouseOver={() => setRead(true)}
                onMouseLeave={() => setRead(false)}
                onClick={handleReadMoreClick}
                className="flex  items-center justify-center gap-1 hover:bg-[#0E3054] rounded-lg md:px-1 cursor-pointer"
              >
                <div  className=" flex items-center justify-center md:w-[2.2rem] md:h-[2.1rem] w-[1.5rem] h-[1.4rem] bg-[#0E3054] rounded-full">
                  <LuArrowRight color="#DBE0E5" size={20} className='md:block hidden'/>
                  <LuArrowRight color="#DBE0E5" size={12} className='block md:hidden'/>
                </div>
                <p
                  className="font-opensans font-semibold md:text-[0.8rem] text-[0.4rem] text-[#DBE0E5] text-left"
                >
                  Read More...
                </p>
              </div>
        </div>
      </div>
    </div>
  );
}
