export const logodata = [
    {
        id:1,
        name:"Quantum Random Number Generator",
        img:require("../../src/Assets/images/qurng.png"),
        para:"Introducing the world's first software quantum random number generator - a tool that uses quantum mechanics to produce high-quality random bits. This technology offers greater security and randomness...",
        Heading:"Convenience of Software & Reliability of Quantum",
        content:"Introducing the world's first software quantum random number generator - a tool that uses quantum mechanics to produce high-quality random bits. This technology offers greater security and randomness compared to traditional methods that rely on predictable algorithms or physical processes. The software is accessible and affordable for all sizes of businesses and organizations and can be used remotely. It is ideal for applications in cyber security, data encryption, and more. Try the software quantum random number generator for a reliable and secure solution to your random number generation needs.",
      },
      {
        id:2,
        name:"QNTSIM",
        img:require("../../src/Assets/images/qntsm.png"),
        para:"A robust simulation tool to benchmark your quantum network architecture against different workloads. Simulate real-world quantum communication applications usingour intuitive web interface. ",
        Heading:"Quantum Network Simulator",
        content:"A robust simulation tool to benchmark your quantum network architecture against different workloads. Simulate real-world quantum communication applications using our intuitive web interface. Designed for corporates to evaluate the power of quantum secure communications, and for academics to advance breakthroughs in quantum networking.",
      },
      {
        id:3,
        name:"QloudB",
        img:require("../../src/Assets/images/qcloud.png"),
        para:"The Blind Quantum Computing project at Qulabs aspires to achieve and provide secure delegated cloud quantum..",
        Heading:"Qloudb: Enabling Secure Delegated Quantum Computing with Blind Quantum Computing Technology.",
        content:"The Blind Quantum Computing project at Qulabs aspires to achieve and provide secure delegated cloud quantum computing for any classical client through blind quantum computing technology. Qloudb is one of the tools we built to provide the facility to delegate universal quantum computation privately to servers (server itself doesn’t have the knowledge of intended computation) such as IBMQ, AWS-Braket and Azure Quantum platforms. Qloudb will serve as an intermediary layer to mask the actual quantum circuit of the user at the same time returning the same outcome in an irreversible manner. With applications provided by Qloudb a user can perform quantum machine learning over encryption quantum data, can perform quantum computations over encrypted graphs over many graph problems like graph clustering, maximum weighted-independent set problem etc., can perform protocol encryption and much more.",
      },
      {
        id:4,
        name:"CHECQ",
        img:require("../../src/Assets/images/checq.png"),
        para:"ChecQ is a quantum vulnerability analysis tool that offers specialized capabilities for detecting and addressing cryptographic quantum vulnerabilities ",
        Heading:"ChecQ: Safeguarding Against Quantum Threats - Detecting and Addressing Cryptographic Vulnerabilities for Robust Cybersecurity in the Quantum Era.",
        content:"ChecQ is a quantum vulnerability analysis tool that offers specialized capabilities for detecting and addressing cryptographic quantum vulnerabilities in websites and software packages. It maps associated risks, allowing organizations to proactively safeguard their cryptographic systems and estimate the potential risk based on the lifetime of their critical infrastructure from potentia quantum computer cyberattacks.",
      },
      {
        id:5,
        name:"Q-Folio Pro",
        img:require("../../src/Assets/images/Q-FolioPro.png"),
        para:" The rapid advancement of quantum computing technology has opened new possibilitiesin the field of portfolio optimization. ",
        Heading:"Q-Folio Pro: Integrating Quantum Computing with RoboAdvisor Technology for Advanced Portfolio Optimization.",
        content:"The rapid advancement of quantum computing technology has opened new possibilities in the field of portfolio optimization. We are creating Q-Folio Pro, an innovative software-based application that combines quantum computing capabilities with traditional roboadvisor functionality to offer enhanced portfolio management solutions. Q-Folio Pro utilizes the principles of Modern Portfolio Theory and incorporates various constraints, objective functions, and advanced analysis tools to optimize investment portfolios. Additionally, it leverages classical, heuristic, quantum, hybrid, and quantum-inspired algorithms to provide comprehensive and efficient portfolio optimization. The frontend and backend of Q-Folio Pro are developed using Angular and Django, respectively, ensuring a robust and user-friendly experience.",
      },
     
 ];