export const numberdata = [
    {
      number:"5+",
      name:"Research Partners"
      },
      {
        number:"4+",
        name:"Global Research Labs"
        },
        {
            number:"45+",
            name:"Quantum Scientists"
            },
            {
                number:"4+",
                name:"Countries World Wide"
                },
    
     
    ];