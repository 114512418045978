import React from 'react';
import { useState } from "react";

export default function Productscard(props) {
    const [isHovered, setIsHovered] = useState(false);
    const gradientStyle = {
      background: isHovered
        ? "#09233e"
        :"radial-gradient(circle,rgb(230, 252, 255,0.2), #051525)" ,
      transition: "background 0.3s ease-in-out",
    };
  
    return (
      <div
        style={gradientStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="xl:px-8 xl:py-6 md:px-6 md:py-4 flex flex-col gap-2 2xl:w-[23rem] 2xl:h-[16.5rem] 2xl:hover:h-[16.4rem] xl:hover:h-[14.9rem] hover:h-[11.9rem] xl:w-[19rem] xl:h-[15rem]  w-[15rem] h-[12rem] 2xl:rounded-2xl xl:rounded-xl  rounded-lg border-[#E6FCFF]/20 md:border-2 border px-3 py-1"
      >
        <div className="flex gap-3 py-1 items-center justify-center">
          <div className="flex flex-wrap xl:h-[2.5rem] h-[2rem]  text-[#B1F6FF] font-opensans font-semibold 2xl:text-xl xl:text-lg  text-md  mb-1 ">
            {props.head}
          </div>
        </div>
        <div className="border-[#D9FBFF]/70 border-2   "></div>
        <p className="text-white font-opensans font-semibold 2xl:text-[0.6rem] xl:text-[0.5rem]  text-[0.4rem]  flex flex-wrap text-justify mt-[0.15rem]">
          {props.content}
        </p>
      </div>
    );
}
