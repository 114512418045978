import React, { useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Logocard from "./Logocard";
import { logodata } from "../Data/Logodata";
import { RxCrossCircled } from "react-icons/rx";

export default function Logoscontainer() {
  const sliderRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedLogocard, setSelectedLogocard] = useState(null);

  const openModal = (data) => {
    setSelectedLogocard(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -250,
        behavior: "smooth",
      });
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 250,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative w-full">
      <div className="flex items-center justify-center md:gap-6 gap-2">
      
        <div
          className="md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-full bg-[#0C2E52] hover:bg-[#0C2E52]/60 flex items-center justify-center cursor-pointer"
          onClick={slideLeft}
        >
          <FaChevronLeft
            color="#1B99D4"
            size={20}
            className="opacity-50 cursor-pointer hover:opacity-100 hidden md:block"
          />
          <FaChevronLeft
            color="#1B99D4"
            size={12}
            className="opacity-50 cursor-pointer hover:opacity-100 block md:hidden"
          />
        </div>
        

        <div
          id="slider"
          ref={sliderRef}
          className="flex items-center gap-6 overflow-x-auto scrollbar-hide scroll-smooth w-4/5 md:p-4 "
        >
          {logodata.map((item) => (
            <div key={item.id} className="w-auto h-auto cursor-pointer">
              <Logocard
                id={item.id}
                logo={item.img}
                para={item.para}
                onReadMoreClick={openModal}
              />
            </div>
          ))}
        </div>
       
        <div
          className="md:w-[4rem] md:h-[4rem] md:rounded-[2rem] w-[2rem] h-[2rem] rounded-full bg-[#0C2E52] hover:bg-[#0C2E52]/60 flex items-center justify-center cursor-pointer"
          onClick={slideRight}
        >
          <FaChevronRight
            color="#1B99D4"
            size={20}
            className="opacity-50 cursor-pointer hover:opacity-100 hidden md:block"
          />
           <FaChevronRight
            color="#1B99D4"
            size={12}
            className="opacity-50 cursor-pointer hover:opacity-100 block md:hidden"
          />
        </div>
    
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-10">
          <div className="xl:w-[40rem] xl:h-[31.25rem] rounded-[2rem] md:w-[36rem] md:h-[28rem] w-[20rem] h-[30rem] flex flex-col items-center justify-start gap-8 py-6 px-11  bg-[#0C2E52]">
            <div className="w-full flex items-center justify-end mt-4 cursor-pointer">
              <RxCrossCircled size={30} color="#03B6CC" onClick={closeModal} />
            </div>
            <div className="w-full flex flex-col items-center justify-center gap-2">
              <p className="font-opensans font-extrabold md:text-2xl text-lg text-[#FEFEFE]">
                {logodata.find((item) => item.id === selectedLogocard.id).name}
              </p>
              <p className="font-opensans font-extrabold md:text-sm text-[0.675rem]  text-[#D9FBFF]/50">
                {
                  logodata.find((item) => item.id === selectedLogocard.id)
                    .Heading
                }
              </p>
            </div>
            <div className="font-opensans font-semibold md:text-[0.8rem] text-[0.6rem] text-[#E6FCFF] text-justify mb-2 overflow-x-auto scrollbar-hide">
            {
                  logodata.find((item) => item.id === selectedLogocard.id)
                    .content
                }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
