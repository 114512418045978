export const professionalsapply = [
  {
    id: 1,
    name: "Position - AI / ML Engineer (Full-Time)",
    job: {
      Introduction:
        "We are looking for an experienced, passionate AI ML engineer to join our team. You will be responsible for designing, developing, and deploying scalable machine learning solutions and applications. This is a challenging position at Qulabs Software India Pvt Ltd., that requires the candidate to learn and internalize AI and ML technology. The candidate will support AI/ML issues and must have the ability to understand and explain technical information in a concise manner with strong communication skills between customers, developers, and researchers. Troubleshoot issues reported by customers across the end-to-end ML functional areas.",
      Qualifications: "BTech/BE/BCA/MTech/ME/MCA",
      Experience: "3-6 years",
      JobLocation: "Hyderabad",
      NumberOfPositions: "1",
      PositionType:"",
      Responsibilities: [
        "Developing data products containing decoupled, modularized, reusable, and scalable training and inference pipelines",
        "Deliver code fully tested, scalable, desired interfaces for data pipelines, systems integration, and model training",
        "Collaboratively solve complex problems, develop complex algorithms and modules, or solve business and scientific problems (Bioinformatics, Finance, Sensing devices, Material science, etc)",
        "End-to-End development of Data-driven products from scratch to deployment",
      ],
      Requirements: [
        "Proven hands-on strong experience with Python and Scala",
        "Experience in Micro-services, database applications, ORM, REST API (Django, Flask), OOP",
        "Hands-on experience with Machine learning and deep learning algorithms and libraries (Numpy, Scipy, Pandas, Sklearn, Tensorflow, Pytorch, Keras, etc) for supervised and unsupervised learning use cases like Time series, NLP, etc",
        "Experience with SQL (Postgres, Mysql) and NoSQL (MongoDB) databases",
        "Understanding of AWS cloud services (EC2, EBS, Sagemaker, Lambda, RDS) and compute architecture (CPU, GPU, TPU) for application development and deployment",
        "Strong knowledge of end-to-end data-driven product development methodologies for real-time web applications",
        "Good familiarity with Git and cloud containerization technologies i.e Docker, Kubernetes, etc",
      ],
      GoodToHave: [
        "Familiarity with Scientific computing and numerical computations",
        "Any experience with Quantum computing (Qiskit, AWS BraKet, Pennylane, D’wave Ocean), Bioinformatics, Finance, Computational chemistry, Material science will be counted as a bonus",
      ],
      Note: "Apply with your latest CV and put SUBJECT as 'AI ML Engineer'",
    },
  },
  {
    id: 2,
    name: "Position - Full Stack Python Developer (Full-Time)",
    job: {
      Introduction:
        "If you love building software and care about delivering the right solution for customers, this is your opportunity to thrive! You will touch everything from front-end user experience to backend service frameworks for translating cutting-edge Quantum Computing solutions to the market. You will be responsible for the design, implementation, and delivery of the critical components related to the business or scientific services we are developing at Qulabs Software India Pvt Ltd. You will develop high-quality, maintainable, and robust custom codes in Python, SQL, HTML, CSS, JavaScript across multiple platforms.",
      Qualifications: "BTech/BE/BCA/MTech/ME/MCA",
      Experience: "3-5 years",
      JobLocation: "Hyderabad",
      NumberOfPositions: "1",
      PositionType:"",
      Responsibilities: [
        "Design and develop full-stack web applications using Python and Django.",
        "Build and maintain efficient, reusable, and reliable code.",
        "Helping team members to craft Quantum computing solutions for research and business use cases.",
        "Create technical designs based on business requirements.",
        "Deployment of the application onto cloud services (AWS/EC2).",
      ],
      Requirements: [
        "Proven hands-on strong experience with Python and Django/Flask (Django preferred).",
        "Experience in Micro-services, multi-tenancy, database applications, ORM, REST APIs (Django preferred), OOP.",
        "Hands-on experience with front-end technologies (ReactJS, Angular, HTML, CSS) and their integration with the backend.",
        "Experience with Relational (MySQL, Postgres) and NoSQL (MongoDB).",
        "Experience with Postman and Git.",
      ],
      GoodToHave: [
        "Familiarity with any Scientific Computing, Machine Learning, or Quantum computing is a plus.",
        "Working knowledge of cloud containerization technologies i.e Docker, Kubernetes are bonuses.",
      ],
      Note: "Apply with your latest CV and put SUBJECT as 'Full Stack Python Developer'",
    },
  },
  {
    id: 3,
    name: "Position - Frontend Developer (Full-Time )",
    job: {
      Introduction:
        "We are looking for a passionate front-end developer to join our team who will be  responsible for developing catchy, interactive, responsive, and robust frontends for high-end Quantum computing applications and services.",
      Qualifications: "BTech/BE/BCA/MTech/ME/MCA and other disciplines",
      Experience: "2-3 years",
      JobLocation: "Hyderabad (Onsite)",
      NumberOfPositions:"",
      PositionType: "Full Time",
      Responsibilities: [
        "Analyzing requirements and designing interactive application frontend based on client and researcher’s specifications.",
        "Developing, releasing, maintaining, and optimizing responsive and progressive Web Apps to ensure a seamless digital experience over different browsers and devices.",
        "Applying best practices and coding patterns to ensure solutions are readable, clean, maintainable, modular, and reusable.",
        "Designing and building applications using HTML5, CSS3, Bootstrap, and JavaScript.",
        "Designing interactive UI/UX with state-of-art tools like Figma.",
        "Partnering and working closely with Backend developers, programmers, researchers, other team members, and product managers to conceptualize, build, test, and realize products and services.",
        "Mentoring junior members of the development team.",
        "Staying updated with the latest industry trends in web frameworks technologies.",
      ],
      Requirements: [
        "Ability to understand the user and researchers’ requirements and translate them to responsive, scalable, and performant interfaces.",
        "Must be able to convert Design/ Wireframe into a functional front-end.",
        "Strong knowledge of JavaScript, HTML5, CSS3, Bootstrap with UI/UX design (Figma or similar  tools).",
        "1.5+ years of hands-on experience with other JavaScript Frameworks, like Angular/Angular.js/React.js/Vue.js.",
        "Should have the ability to integrate third-party libraries and API's.",
        "Experience with common version control systems like git.",
        "Excellent ideation and Communication skills.",
      ],
      GoodToHave: [
        "Working knowledge of Python/PHP (Python Preferred)",
        "Knowledge of deployment over cloud services like AWS/Azure",
        "Experience with Scientific software development or Quantum Computing",
      ],
      Note: "Apply with your latest CV and SOP with SUBJECT as 'Front-End developer'. We look forward to working with you.",
    },
  },
  {
    id: 4,
    name: "Quantum Computational Chemist (Full -Time)",
    job: {
      Introduction:
        "Qulabs is looking for an enthusiastic Computational Chemist to join their team of researchers and engineers to accelerate the Quantum Simulation revolution.",
      Eualifications:
        "Ph.D./M.Tech./M.Sc or above in Chemistry, Applied Chemistry, Computational Chemistry, or related fields.",
    Experience:"",
      JobLocation:
        "Intern (Remote) Full-Time (On-site: Hyderabad) ** Based on the company’s requirements.",
        NumberOfPositions:"",
      PositionType: "Intern/Full-time",
      Responsibilities: [
        "Specific tasks include perform quantum chemistry simulations, understanding and reproducing results in common literature, implementing novel ideas to solve specific problems related to Quantum Chemistry, Material research, Pharamaceuticals and related fields.",
        "Additional job functions include writing invention disclosures, writing proposals, publishing papers, attending and presenting at conferences, and briefing internal and external customers.",
      ],
      Requirements: [
        "Research experience in one or more of the following areas: Quantum Chemistry, Molecular chemistry, Material research, Medicinal or Pharmaceutical Chemistry, Molecular Dynamics, DFT methods, etc.",
        "Experience or familiarity with numerical simulation tools like Quantum Espresso, VASP, or other relevant simulation toolsets.",
        "Excellent coding skills in programming languages C, C++, Python (Including Mathematica, Matlab) and familiarity with open-source packages for computational chemistry such as pySCF, pyQuante, etc.",
        "Strong desire to learn new technologies and flexibility to work on different projects.",
        "Experience in developing innovative solutions based upon the application of relevant research results from a wide variety of sources.",
      ],
      GoodToHave: [
        "Any experience with Quantum Computation, Quantum Simulation, and variational quantum algorithms will be counted as a bonus.",
        "A record of publications in top-tier, peer-reviewed journals.",
        "An excellent record of teamwork demonstration to work with a team of Research Assistants/Associates.",
      ],
      Note: "Apply with your latest CV and SOP with SUBJECT as 'Computational Chemist'. We look forward to working with you.",
    },
  },
  {
    id: 5,
    name: "Quantum Optics Scientist (Experimentalist)",
    job: {
      Introduction:
        "Qulabs is looking for an enthusiastic Quantum Optics Scientist (Experimentalist) to join their team of researchers and engineers to accelerate the Quantum Memory and Quantum Network revolution.",
      Qualifications:
        "Ph.D./M.Tech. or above in Physics, Applied Physics (Quantum Optics/Non-linear Optics), EE, or related fields. Experimental expertise is highly required.",
        experience:"",
        jobLocation:"",
        numberOfPositions:"",
      PositionType: "Full-time",
      Joblocation:
        "On-site (Hyderabad/Roorkee/Kanpur) Based on the company’s requirements.",
      Responsibilities: [
        "Specific tasks include designing, simulation, calculation, and construction of electrooptical experiments and prototypes of Quantum Communication devices, data collection, and analysis, presentation of the results to team members.",
        "Additional job functions include writing invention disclosures, writing proposals, publishing papers, attending and presenting at conferences, and briefing internal and external customers.",
      ],
      Requirements: [
        "Research experience in one or more of the following areas: quantum optics, nonlinear optics, atomic physics, cavity optomechanics, semiconductor device physics, device design, quantum entanglement, integrated photonics, cavity optomechanics, ion trapping, cold atoms, optical metrology, optical spectroscopy, and RF engineering.",
        "Strong desire to learn new technologies and flexibility to work on different projects.",
        "Experience with building, characterizing, and using parametric down-conversion systems or other sources of entangled photons.",
        "Experience in building quantum communication systems, including working with single-photon sources and detectors, characterizing and using high-efficiency low noise optical frequency conversion systems, and building, characterizing, and using parametric down-conversion systems or other sources of entangled photons.",
        "Experience in developing innovative solutions based upon the application of relevant research results from a wide variety of sources.",
        "An expert in the lab with a proven track record of problem-solving in more than one of the following: free-space optics, fiber optics, integrated photonics, microwave systems engineering, custom electronics design, vacuum equipment, and hardware, cryogenic equipment.",
        "A record of publications in top-tier, peer-reviewed journals.",
        "An excellent record of teamwork demonstration to work with a team of Research Assistants/Associates.",
      ],
      GoodToHave: [
        "Experience with multi-physics simulation (both device and optical system), computer-aided design, layout, and testing.",
        "Coding skills in programming language C, C++, Python, and Assembly levels and good knowledge of computer architecture (Including Mathematica, Matlab).",
        "Exposure to nanotechnology/nanophotonic lab.",
        "Any experience with Quantum Network Component development will be counted as a bonus.",
      ],
      Note: "Apply with your latest CV and SOP with SUBJECT as 'Quantum Optics Scientist (Experimentalist)'. We look forward to working with you.",
    },
  },
];


export const internsapply=[
  {
    id: 1,
    name: "Quantum Communication Intern II",
    Job:{
      Introduction: "Qulabs is looking for an enthusiastic Quantum Communication Intern to join their team to accelerate the wave of Measurement-based quantum computing and Blind quantum computation.", 
      Qualifications: "B.Tech/MTech (CS/EE/ECE) M.Sc. (Physics/CS)", 
       
      Joblocation: "Remote", 
      PositionType: "Intern",
      Responsibilities: [ 
      "Theoretical survey of existing research papers through simulation followed by implementation.", 
      "Reporting daily and weekly updates, with reports and presentations.", 
      "Debugging and optimizing IBM Qiskit Simulator along with Backend Hardware for error-free compilation of quantum circuits.", 
      "Writing research papers and software packages." 
      ], 
      Requirements: [ 
      "Experience in working with Quantum computation and information.", 
      "Knowledge of IBM Qiskit with along with backend hardware simulation.", 
      "Good Programming expertise in Python.", 
      "Intermediate experience with software package development." 
      ], 
      GoodToHave: [ 
      "A good grasp on Quantum Information and Computation.", 
      "C/C++ knowledge will be appreciated.", 
      "Experience with AWS Braket or Azure Quantum will be counted as a bonus." 
      ], 
      Note: "Apply with your latest CV and SOP with SUBJECT as 'Quantum Communication Intern'" 
      }
  },
  {
    id: 2,
    name: "Quantum Communication Intern I",
    Job:{ 
      Introduction: "Qulabs is looking for an enthusiastic Quantum Communication Intern I to join their team to accelerate the wave of Quantum secure direct communication and secret sharing.", 
      Qualifications: "B.Tech/MTech (CS/EE/ECE, pursuing/graduated)",  
      Joblocation: "Remote", 
      PositionType: "Intern",
      Responsibilities: [ 
      "Theoretical survey of existing research papers through simulation followed by implementation.", 
      "Reporting daily and weekly updates, with reports and presentation.", 
      "Debugging and optimizing IBM Qiskit Simulator along with Backend Hardware.", 
      "Writing research papers and software packages." 
      ], 
      Requirements: [ 
      "Good knowledge of Data Structures, Design and Analysis of Algorithms, Computer Organization and Architecture, Computer Networking.", 
      "Programming expertise in C and Python.", 
      "Knowledge of IBM Qiskit with Quantum computation and information." 
      ], 
      GoodToHave: [ 
      "A good grasp on Quantum Information and Computation, Quantum Algorithms and Complexity, Quantum Communications and Error-Correcting Codes." 
      ], 
      Note: "Apply with your latest CV and SOP with SUBJECT as 'Quantum Communication Intern I'." 
      }
  },

  {
    id: 3,
    name: "Post Quantum Cryptography Research Intern",
    Job:{ 
      Introduction: "Qulabs is looking for an enthusiastic Cryptography Research Intern to join their team of researchers and developers to accelerate the wave of cryptographic revolution.", 
      Qualifications: "BTech/BE (EE, ECE, CSE), M.Sc./MTech (CSE/Mathematics/Applied Mathematics) Basic cryptographic knowledge is a must.", 
      Joblocation: "Remote", 
      PositionType: "Internship", 
      Responsibilities: [ 
      "Theoretical survey of existing research papers through simulation followed by implementation.", 
      "Performing Simulation with SAGE, GP/Pari, and Mathematica.", 
      "Reporting weekly updates, with reports and presentation.", 
      "Writing research papers and software packages." 
      ], 
      Requirements: [ 
      "Understanding and familiarity with theoretical concepts of (at the basics level) Symmetric and Asymmetric key cryptographic protocols like DES, AES, RSA, DSA, and Cryptographic Hash Functions.", 
      "Discrete Mathematics, Algebra, and Probability theory.", 
      "Background with Algorithms, Complexity Analysis, Basics of Automata Theory." 
      ], 
      GoodToHave: [ 
      "Knowledge in Number theory.", 
      "Coding skills in programming languages C, C++ using SIMD, AVX-based instructions at Intrinsic and Assembly levels, and good knowledge of computer architecture. (Including Java, Python).", 
      "Any experience with Cloud software development will be counted as a bonus." 
      ], 
      Note: "Apply with your latest CV and SOP with SUBJECT as 'Cryptography Research Intern'. We look forward to working with you." 
      }
  },


];

