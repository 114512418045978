import React, { useRef, useState } from "react";
import Labcard from "./Labcard";
import {labdata} from "../Data/Lab";

export default function Labcontainerr() {

  return (
    <div className="relative w-full">
  <div
    className="flex flex-wrap max-w-[80%] mx-auto items-center justify-center md:px-20 md:py-10 px-3 py-7 cursor-pointer"
    style={{ gap: "0.5rem" }} 
  >
    {labdata.map((item) => (
      <div key={item.id} className="flex-item" style={{ margin: "0.25rem" }}>
        <Labcard
        id={item.id}
          img={item.img}
          name={item.name}
          location={item.location}
        />
      </div>
    ))}
  </div>
</div>
  );
}
