import React from "react";
import Services from "./Services";
import { data } from "../Data/Servicesdata";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useState } from "react";
import { numberdata } from "../Data/Numbersdata";
import Numbercomponent from "./Numbercomponent";

export default function Numbers() {
  const [slideicon, setslideicon] = useState(false);
  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 350;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 350;
  };

  return (
    <div className="flex flex-col pt-20">
      <div className="flex flex-col items-center justify-center gap-6">
        <p className="text-white font-opensans font-semibold 2xl:text-3xl md:text-2xl">
        We Take Pride in Our Numbers
        </p>
        <div className="sm:w-[100%] w-[90%] flex flex-wrap items-center justify-center sm:gap-5 gap-2 mb-8">
        {numberdata.map((item) => (
                <div key={item.id} >
                  <Numbercomponent
                    number={item.number}
                    name={item.name}/>
                </div>
              ))}
        </div>
        </div>
      </div>
  );
}
