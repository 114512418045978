export const professionalroles=[
    {
        id:1,
        name:"Position - AI / ML Engineer (Full-Time)",

    },
    {
        id:2,
        name:"Position - Full Stack Python Developer (Full-Time)",

    },
    {
        id:3,
        name:"Position - Frontend Developer (Full-Time )",

    },
    {
        id:4,
        name:"Quantum Optics Scientist (Experimentalist)",

    },
   
    {
        id:5,
        name:"Quantum Computational Chemist (Full -Time)",

    },

];




export const internroles=[
    {
        id:1,
        name:"Quantum Communication Intern II",

    },
    {
        id:2,
        name:"Quantum Communication Intern I",

    },
    {
        id:3,
        name:"Post Quantum Cryptography Research Intern",

    },
   ,

];


