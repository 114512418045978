export const ipdata = [
    {
        logo:require("../../src/Assets/images/Quantum Secure Direct Communication with mutual authentication via rotation of an arbitrary basis..png"),
        name:"Quantum Secure Direct Communication with mutual authentication via rotation of an arbitrary basis.",
        domain:"Domain- Quantum Communication"
      },
      {
        logo:require("../../src/Assets/images/Techniques Of Quantum Computing Model.png"),
        name:"Techniques Of Quantum Computing Model.",
        domain:"Domain- Quantum Computing"
      },
      {
        logo:require("../../src/Assets/images/Simulator for Quantum Computing systems..png"),
        name:"Simulator for Quantum Computing systems.",
        domain:"Domain- Quantum Computing"
      },
      {
        logo:require("../../src/Assets/images/Measurement Device Independent-QSDC with user authentication. (2).png"),
        name:"Measurement Device Independent-QSDC with user authentication.",
        domain:"Domain- Quantum Communication"
      },
      {
        logo:require("../../src/Assets/images/Quantum Memories (Ultra-long storage assisted 2nd Gen Quantum Memory using intrananotube double-dot heterostructures).png"),
        name:"Quantum Memories (Ultra-long storage assisted 2nd Gen Quantum Memory using intrananotube double-dot heterostructures)",
        domain:"Domain- Quantum Memory"
      },
      {
        logo:require("../../src/Assets/images/Quantum Memories (Rare earth doped Transition Metal Dichalcogenide thin film for quantum storage of light).png"),
        name:"Quantum Memories (Rare earth doped Transition Metal Dichalcogenide thin film for quantum storage of light)",
        domain:"Domain- Quantum Memory"
      },
      {
        logo:require("../../src/Assets/images/Secure Multiparty Quantum Conference.png"),
        name:"Secure Multiparty Quantum Conference",
        domain:"Domain- Quantum Communications"
      },
      {
        logo:require("../../src/Assets/images/Secure quantum multi party XOR protocol.png"),
        name:"Secure quantum multi party XOR protocol",
        domain:"Domain- Quantum Communications"
      },
      {
        logo:require("../../src/Assets/images/Quantum Random Number Generation (A software QRNG with Digital Quantum Simulation).png"),
        name:"Quantum Random Number Generation (A software QRNG with Digital Quantum Simulation)",
        domain:"Domain- Quantum Communication"
      },
      {
        logo:require("../../src/Assets/images/Long Distance Measurement Device Independent-Quantum Secure Direct Communication protocol with EPR pairs.png"),
        name:"Long Distance Measurement Device Independent-Quantum Secure Direct Communication protocol with EPR pairs",
        domain:"Domain- Quantum Communication"
      },
      {
        logo:require("../../src/Assets/images/Optimal quantum routing protocol and path finding algorithm for quantum network.png"),
        name:"Optimal quantum routing protocol and path finding algorithm for quantum network",
        domain:"Domain- Quantum Networks"
      },
      {
        logo:require("../../src/Assets/images/Software platform for modelling and simulation of Quantum Network communication system..png"),
        name:"Software platform for modelling and simulation of Quantum Network communication system.",
        domain:"Domain- Quantum Networks"
      },
];